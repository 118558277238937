import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const MdxIcon = makeShortcode("MdxIcon");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "everything-you-need-to-work-with-learn-about-and-contribute-to-carbon"
    }}>{`Everything you need to work with, learn about, and contribute to Carbon.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Development resources</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Design resources</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Tools</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Reading</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "development-resources"
    }}>{`Development resources`}</h2>
    <h3 {...{
      "id": "github-repos"
    }}>{`GitHub repos`}</h3>
    <Row className="resource-card-group" mdxType="Row">
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Carbon Components" href="https://github.com/carbon-design-system/carbon-components" mdxType="ResourceCard">
          <MdxIcon name="github" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Carbon Components React" href="https://github.com/carbon-design-system/carbon-components-react" mdxType="ResourceCard">
          <MdxIcon name="github" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Carbon Components Angular" href="https://github.com/carbon-design-system/carbon-components-angular" mdxType="ResourceCard">
          <MdxIcon name="github" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Carbon Components Vue" href="https://github.com/carbon-design-system/carbon-components-vue" mdxType="ResourceCard">
          <MdxIcon name="github" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Carbon boilerplate" href="https://github.com/carbon-design-system/carbon-boilerplate" mdxType="ResourceCard">
          <MdxIcon name="github" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
    </Row>
    <h3 {...{
      "id": "storybooks"
    }}>{`Storybooks`}</h3>
    <Row className="resource-card-group" mdxType="Row">
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Carbon React Storybook" href="http://react.carbondesignsystem.com/" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeUlEQVQ4y3WVSWhUQRCGe5K4RlyYMDNvxARFxQW9qHiLEhXxJsGc9CAiCAFBQeICIoiKx0AIikQ9xCUuROK+ezBGBY0eNC4RBRE95JKDevEwfhX/xpqHDnypqq7qftXV1Z0Q+OXz+SCZkayCmqAfeiVUODuTy+WSaKMHv04crJBcjOMpshd5GZaGUBbXIt8z6HAf+bugKQRlNOEe9mZluRE+wG6YBL34e5ALLWP0B8j1mlcZ/OqSk3H0wXjnGwM3oQTNPlvsrdDmyvJn/652OcsCprhJlsl5eIu+JX5Espnxo3HBWMuR+sWio99HT1xdrkE3zIFhaHL13Au73E7K987ANOwXSKvjFfgIQ3Bd234EP3VoXchBOJw+bVuoFi7CQ3gCN2A5fCFoZqpuBxm7jWyAb7YD7H7k9hiwBD7BTtl1CjqL3KOxHcpwtew70A4nZC8A+0h3UNqrUr3YBV8V3FQoFEoG+i/Gs7BMdn2qR08GpT1bk8fJ0YH+LrZGkiQltY0xC+ZqwUXxhCUP2Z912vIKDU4EO+nnsEFjV+E7HJDdo5h22WNJos3qH+u4Eux0z8EpOA3z4BWMSh3KJiZbK82A99AKL62eIztMtU0jfIYBKzK+HxaMPILshOPKdJAx64Qh5AWoDali+kVv6a5mrbbwWm1Urw/sh6mK3QctrrEzZa+Erl6f1dE3O1xSdq3p7dt4vKL+tYl3uUZPU3VqYmexWCzhWxPfS7fgsX/dZf9IPkZvkD7fnig4Y+8ivLHWgAny2yFuK3tt/KsiuZYFBsBapT9OkC+rLrDredfuPHHVZff4P/8CptshxJbRE1fl4utsFzA6NS/8Bjlew89F2xUNAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "react icon",
              "title": "react icon",
              "src": "/static/8cf88522cf29f15ed07205684ebef753/7d71f/react-icon.png",
              "srcSet": ["/static/8cf88522cf29f15ed07205684ebef753/7d71f/react-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Carbon Components Vue Storybook" href="http://vue.carbondesignsystem.com/" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "128px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAC4jAAAuIwF4pT92AAADe0lEQVQ4y5VUb0hTURQ/c0ZJUa5EovqSfanMtjnp35fQMuckB0UklW5TzCRqkpa6996e/7L/0B8oiD4lGZWiZbbNLVdqWhGE0JcI+itBQfWloHS7t3Pvu8sMRLvwuPedd87v/s7vnPMApljpVhek2vbwszHXoYMp1z2PHoJKki6gJMJQvSFZPWhYl7HLkGZ3GUxWRyJz2ZBdBqsFGO6zjLnOBeYch2G11WGAkNeA8Ym4L4CAPEcD7VE60PgNwt53M9o9I2n5rhFTjuM9Bn5FVjuZy8bNBxjT2fg+aLQ5P1syC98nN7pHoE8dwfgPGP8JfNJ6DdAv5cBAI4X7KoXBBrqwsYJashzUnF9KEeDtKlvhXJFyncW+j5q3OOnKglIa5/dSCGPM0+MUeuR2DayvIU5jKbfAoyYE9f7S+eTo8oKSqDnb8ctiL2OgnlTb7vm4/zTaXDQ9qyiSeO5QFPrVCAQRNKj8gO7aFA3QJ2mA3bVL8cN3COGNfWp03oVKkr6pKGrKK2aAH/HpN28toWiLLCsto9DLgUbhcTNF7Ro4xn2vXgMNiUNAVrlDUBljASl7yxgAMea5qDmvhJpynVHUliZcrSbwENk9qGe+r6G9arYghYV7c14ccLVVJqDDK+6IAQkt1dRodVCT1Ymgzogls4guObyfYAYECzHGJfJLu0RhBbuuGphg8EsF3LFHibDAxdX7CWpGkB1ZZXeR+A4PS3eMF7FHCfOYF2d00HGEZQixlJFdFcDwKZ0AD3FQDNR3SjR1WzHJ2FhIko5VECwEY0d4R/iktRoJWavD3VoB2FYJEz74pDV4CWNAYaCOJDe5yYodJQTloGgfg6GjrE2ucN+wqgf3dpbZX9NypRx19MAfB61Al2CombEc1XdJdGZrDcHGj0KvygrxFdksEpdrWXVW/zOCHYcBKGWjqBPaLsTALxCuY6yiCKZVn7ELyFVCqniR2SRz3SU0CArHgFzBARhQEIvU38BkeAGXy+P/aDZ8CuDmoUkATxdhc6rj4p4t1iPAMAcKeUf57pfsE7riTs2Ufx8Q7GJtZONp81aS73Db4NE4aHVr3TGtFVQAbqFMA7E5V25zwG7PcnGJZr/mniZgLI1YoF9ah0+dmNfY7MP/rVjXPzsxbqM3ATzbAF6enYZ2/64b2OzvLmrnJ80Az08CXK/Q3juPTBr2G0OtsIlZhO5uAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "vue icon",
              "title": "vue icon",
              "src": "/static/988dc0d391f0a74acf4433594029db2c/c8369/vue-icon.png",
              "srcSet": ["/static/988dc0d391f0a74acf4433594029db2c/c8369/vue-icon.png 128w"],
              "sizes": "(max-width: 128px) 100vw, 128px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "design-resources"
    }}>{`Design resources`}</h2>
    <h3 {...{
      "id": "theme-libraries"
    }}>{`Theme libraries`}</h3>
    <Row className="resource-card-group" mdxType="Row">
  <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="White theme" href="sketch://add-library/cloud/JaVzz" mdxType="ResourceCard">
      <MdxIcon name="sketch" mdxType="MdxIcon" />
    </ResourceCard>
  </Column>
  <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Gray 10 theme" href="sketch://add-library/cloud/Onwv2" mdxType="ResourceCard">
      <MdxIcon name="sketch" mdxType="MdxIcon" />
    </ResourceCard>
  </Column>
  <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Gray 90 theme" href="sketch://add-library/cloud/eo37p" mdxType="ResourceCard">
      <MdxIcon name="sketch" mdxType="MdxIcon" />
    </ResourceCard>
  </Column>
  <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Gray 100 theme" href="sketch://add-library/cloud/d13Ll" mdxType="ResourceCard">
      <MdxIcon name="sketch" mdxType="MdxIcon" />
    </ResourceCard>
  </Column>
    </Row>
    <h3 {...{
      "id": "color-grid-and-icons"
    }}>{`Color, grid, and icons`}</h3>
    <Row className="resource-card-group" mdxType="Row">
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="RGB color palettes (.ase and .clr)" href="https://www.carbondesignsystem.com/files/IBM_Colors_RGB.zip" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "560px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101.60714285714285%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsSAAALEgHS3X78AAAEMUlEQVQ4y52Se0zTVxTHj/RdKcJQdKBFQIYKxoABzRa2uT9cJmxiMmeZGw9DImWbmUYe5Y0wHOpEIMKQGEW0POY2siUScCBvFkbGgL5LgdJ3aekb2v5+LbtE4/bfkt187sn3nHu+uTc3B+x2x8b/Wg6HA6w2G1JerxeF/+Ll8nhwr9djtdrAZLKg3OnEtRpcrcL1OtxsxuVLm6zoNytiISYRYYYVbGHeKRKu8wU6s9nucrkMhlUwGFc3NnCV0l1wEbuYhV0rxTq52Adv4yfewq9XYuzz9kCaZSfDUvedI+7QPJ3M3RFYOzW17HI51Got6PUG7wa2LHchZ1YqVpaHtd13J8bibx7CKzhY2lkbAUxUMNdU2cNC2wEqKeQbY2Myu90il6tAq9V7PC6VYr2qCC++jNdfx35+4mZ9uHHm5Eb9DfxSzlr4rvU3Qtw3a3QJ8XeDApsi9raMj88bV42yRTmoNTq326lW25sbDfW1hocPVsdGjbduLN/8VvFrt/6nJ4tVV2fLSnq7uyevVXfm5d4vKW6bmZWoNVrJ/AIolRqPx/nXtCmAIfQB/v590qrKeQI8A+hjnZlJZf0CkA1wKi/3XigzBeAonXq8v39Co9UIRRJQKNS4Z336TxODIgTg7WNKK8qkyIn4OGXu9OnbAEkAKZe+vrt7dzLAETI5sffZqFKt4glEsKxQud1rfJ4p8ZgsNkZ6KmmpuWkpLmbi8MFxdvZw9oW6yPAvoyK/qq5uf+/dnMiIs7GH058/n1iSy2d5AlheVqIhM5utBoMFYTRaTCar0WiVLSp4fAFfIJrjCRF8oXiOL3yhhSKxWCKdmeOBXK60WOxoVCxWK9qbWG1yhZIvEPJFYuQRiDZ5JRDowSidnpkDrU6FYY51p9XptCFcrjWtXiORiaULEqkM/agUCRQ3hUzyCpTyhTzo65OMDK8MD2mHh/RI9PTMdnQMcbmDXT+MdnaOPGrtb+8Y5j4efPRwoKNrpBMV/2EIIpg9DPJUAP13f/qUH7WVRirw21rKjLhFI3EY9JLo+JagXdUo3R/3/TZGGY1cSKcUbaUWUYiFzJAKYL7+lLpl0pf0B43QSiHmAVw+kNCSz9W9tv2bj74YZHEm94TXshukydkD2/zKiZBHJhRQCAUEyA8OKoPQ4Kc0n2k6qZVCyKWSirbAlZNZv+W2qY8ldUXFNbMKJ6OP3suukySm/EgjFZJ9OBQCh0rkEKEgZGc5urmXBI/JPlfQARHyGb7laZUzJzL6Uounwg40nK/hJ18YQPH9zL4A/0rUsOn34RCgIDioHMJDG+nkQj96EYNW7EspDAyoCIuqpRLyIg7WxcQ3x73zwN+vNDqh+cjx1h3br6IGBq0INSPL3j0VwM5pSk+7k5HR+AKkPz/XkJnZ9Nmn9edYdamf1Kan33kp/t2W3shmN/8NrhDb+/RNhusAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "ase",
              "title": "ase",
              "src": "/static/cde57da39cf470f47832ecc5eb6899c0/30efb/ase.png",
              "srcSet": ["/static/cde57da39cf470f47832ecc5eb6899c0/d6747/ase.png 288w", "/static/cde57da39cf470f47832ecc5eb6899c0/30efb/ase.png 560w"],
              "sizes": "(max-width: 560px) 100vw, 560px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="IBM Design Language library" href="sketch://add-library/cloud/nwqmk" mdxType="ResourceCard">
          <MdxIcon name="sketch" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="IBM Icons (16px, 20px) library" href="sketch://add-library/cloud/KW2yr" mdxType="ResourceCard">
          <MdxIcon name="sketch" mdxType="MdxIcon" />
        </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="IBM Icons (24px, 32px) library" href="sketch://add-library/cloud/2bwkM" mdxType="ResourceCard">
          <MdxIcon name="sketch" mdxType="MdxIcon" />
        </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="IBM Grid template" href="https://sketch.cloud/s/ngV7z" mdxType="ResourceCard">
          <MdxIcon name="sketch" mdxType="MdxIcon" />
        </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="UI Shell template" href="https://sketch.cloud/s/EjVmA" mdxType="ResourceCard">
    <MdxIcon name="sketch" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
    </Row>
    <h3 {...{
      "id": "github-repos-1"
    }}>{`GitHub repos`}</h3>
    <Row className="resource-card-group" mdxType="Row">
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Carbon Design Kit" href="https://github.com/carbon-design-system/carbon-design-kit" mdxType="ResourceCard">
          <MdxIcon name="github" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="IBM Design Language and Icon Kits" href="https://github.com/IBM/design-kit" mdxType="ResourceCard">
          <MdxIcon name="github" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
    </Row>
    <br />
    <h4 {...{
      "id": "ibm-plex"
    }}>{`IBM Plex`}</h4>
    <p>{`Carbon uses the open-source typeface `}<a parentName="p" {...{
        "href": "https://github.com/ibm/plex"
      }}>{`IBM Plex`}</a>{` – carefully designed to meet IBM’s needs as a global technology company and reflect IBM’s spirit, beliefs, and design principles.`}</p>
    <h4 {...{
      "id": "ibm-design-language"
    }}>{`IBM Design Language`}</h4>
    <p>{`Carbon delivers the `}<a parentName="p" {...{
        "href": "https://www.ibm.com/design/language/"
      }}>{`IBM Design Language`}</a>{` as tools for designers and developers, guidance, tutorials, and support.`}</p>
    <h2 {...{
      "id": "tools"
    }}>{`Tools`}</h2>
    <Row className="resource-card-group" mdxType="Row">
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Carbon CodePen" href="http://www.codepen.io/team/carbon" mdxType="ResourceCard">
          <MdxIcon name="codepen" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ResourceCard subTitle="Color contrast checker" href="https://marijohannessen.github.io/color-contrast-checker/" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE5klEQVQ4y0WUCVBWVRiGL8jygwtGmpoKMy5YjrsO2oimjKZluGsZ5jKpoRhpKpaNG1nmVg6CpSOGprhliWsumEZuuCtYuGeCOgoobvzLfZ8OP1pn5s6cuXPOM+97vu97rS1lvhb0scrXeTugkvVslRVZzT0F/kn2ZccxnQsu5WiwR/uDCrU9cIs2+I1UmlW9/JwWp1hM8/Up3zM1wLISaOIFZOHwwpxPrXoeZ+AGlVaDstpwOxzyG8KpRnC4ARysD7tqovQqt7XEb8JzAUyq5Pt8b60l0As75PTpel2VS+BleBohFbd2qbCDrStR4nwn6VhHaX+kh60t3Kw38JU1YLZjp8ZbQV7oOF9f6xvb3wtb7vSJylQV5RLOPVo58URJj3qiu32lG4Ok/MHi1ECRHSN2dkPrOtikNnWywLj42HFATa3/FY58aIUm2cGFqwljn9q48onmCf2Fc7h0P87YTkBXJ0DuR3B0NOyNFRtiYKlRntTMyeRaEBuQ/B+wvycgeSK1WaAWzg0GdliDdJMx8thT4MksVDQXbs5D+V/CyemQZeAbR4ilvY3ljiI+QgwKgW4+7aw6D61akXaV+4NpRKI62qnqp20arbP6jBLmg/t7VJqO7qw2KleiM0tg/xwD/ARSh4kZb0JcWxcD6kKUI91yuP1j61OTzjR3D1M3zdRQ/cAk9msel0mjTD+bau9Gxfvgn53o3HoDXArrZ0NyPEwz7/pBJ5tejaFN1QIrwBOeUoeWtFMXd4wGaBwf8rWms54UjmgTtzmA7DPoUR4qPAHn9qB9a9CaRbDQPMnk90VsNxHdDF4JleVQ1K4w+tBBwz19maixJJGkFFYog13s4bxOU8pNcBUb4CV0Mhvt2AQrklHSp2jscOj7hmjfHOq9aBvgu3vCiDfAzz39tEjxxvAcMklTNjuVxyluUagyyh4JXXtgmjsfbf4NpWSgaQth5CTU8z3RsivUiLCtQE1Kq8s8IpXq7q0MjeFXZiqH73SJXyjikO3hwn24dkWUnDE1yrJhXRF8m4sSjf1hq0X3eaLZBBQ6WJafFsW9xGpasdndw5RiuE4rUddZaIyucomt90wN8sUfh0TeXlGwGR4uB88cJyTcgsEnpde320SkQbUviizLXtuomjKdEWTTSbkaQIF5xydMf2pEmPNpF0RGtumSzWLvj3BimbhoBN1JNC06EuntxxB53U3YaRSUmeltbIdyNtalgNbcdXWXiyEPUfwNMe2c+OogLPpJLE4V6QvEtiT4fYo4O1q62l+61xk5m8pNqAtVuvJWxajoWpOqlLjMuBNZjKfHVfHOKWlUFkrYKCYvFYlmQOaYXl4Wr/Ih0e7+6IipQ14rOUtNT5cEsbVC3eNSbzj4ORnxgrHZ8CK0y5E7ejfErDNPlIJiZ6KhY2DsEDRzIFpshiPdhM+WlnIeMWl2vLL+/sty1axQNx4r5GqxNyn8LmhcyHEI3wvN1sjdPhl35xnY0XEoujfq3QWNisIztS2uuU1MJNaBjMq6vMOiQfn9HH89C+g/XVboJZcX6rudrsGrlFsjBcJmQeOx8Go/87WHtk2gl8naEcZiQvXyKCR9vkVI+b3dAVTA/ApVAfXIqpzz7GcMvn4zGBIUx5aq/bge8hrOkHBUqxoPWlXhbI9AkmN9aPM8rdb54xWzK1jWv7eeA44S7vPdAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "color contrast icon",
              "title": "color contrast icon",
              "src": "/static/0221b06df5d630131fb1efd656f319b3/7d71f/color-contrast-icon.png",
              "srcSet": ["/static/0221b06df5d630131fb1efd656f319b3/7d71f/color-contrast-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "reading"
    }}>{`Reading`}</h2>
    <h3 {...{
      "id": "featured-stories"
    }}>{`Featured stories`}</h3>
    <Row mdxType="Row">
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="Smashing Magazine's “Design Systems” Book" href="https://www.smashingmagazine.com/design-systems-book/" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.199999999999996%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACqklEQVQozwGfAmD9ANHW3c3T18vP0sfM0czDxvFeYvRFSfRKS/ZHSvRKTvFTWPJSVe9cYP5SVq9XWXh7e46GhJiRj56Xl6eiogDJz9bDyM7AxMi8wcbEub3xWl70Qkf0R0n0RUf0Sk7yUVXyUFTvTFH+TVGnUFFqa2qIfHqZj42jm5qloKAAx87Ww8jQvsHIusDHxLe871ZZ80FF80VJ80NG80dL8E1R8k1Q7k5S/0dKmUBAWVpYg3Z0k4mGm5GQoZmZAMLL1cHH0bzAyLG5wta2tfVUVfE9QfI7P/I8P/FESO9OUvFLUO5PU/o9QcNfWn1xZnBiYYN5d4qCgJWMjQDEytHAxMy4usK2usHVo5vkQETxbXbvgofyVFfyP0LvS0/wTFDwSk71YmPnppOccFtgU1B0aWh+dnWLg4MAyM/Tw8nMvsDD29PQ1K+cuVtQ3ElQ5V5k7U5T80JG8ExR8EpO+EpPwl1ezLGhwntbg1tLZmFhdm9vhHx9AMjQ1cLJztDS1PHk286jjpZiVLkoLeo4PO08P/NFSfFMUPNKTfFPU+J/e+XGtcaEZatxVGlfX3dub4d/fQDDyc69wsja19ju3tXGl4CSYlOwLjT9REfzQ0XzRkjwRUv1TVHgQEiwj4ns0LfHk3TGi2h9ZVxqY2ODengAvsbMucLJ1NTR5sy+w4p1f1tPtTA3/kVI8kNF9EpO8U5U9VBU509WrWdn0J6E1KODsohtfWJXWVJRb2pnALvDybW/xMTN19a9rM9xX9xGSfBFSfdGSvZFSfRNUPJSVvRNUfZPUvE/RMRaUL6Se4djVmxZUmRaVXdwZwC3wcizv8S5ws7X2NbkuKb3UlT4TFD3Sk73Skz1UFPzUlf2TlH3REj8Q0jDaWGFaF1yYF5nXFduZV5+dmxQOXgjeLaf7AAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 01",
              "title": "Featured 01",
              "src": "/static/529e9fe384d5fe1ba1314a1940e79338/70165/Featured_01.png",
              "srcSet": ["/static/529e9fe384d5fe1ba1314a1940e79338/d6747/Featured_01.png 288w", "/static/529e9fe384d5fe1ba1314a1940e79338/70165/Featured_01.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="Winter 2017 HOW In-House Design Award Winner" href="http://www.howdesign.com/84-award-winning-projects-from-in-house-design-teams/" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.199999999999996%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACRElEQVQozyVRiU4aURTloxVbbZume+2WuJW4RIlpaUsiVotaIyPYyqIswyIIwzKAw+wM82bR2b3TJi83757z7nLOC7ieN+z3IXYIguqTjuviF1nbcQWKNDTE80K31XI8r1quehBLOEtRtm2PhkPXdQOmYUSiMdOyNtY3Y7v7uq4HZ+YUVU0dxycil/qTCa2GHcd5Pb8Axbu7e7ncJU3Ta+thz3MDAI0GpGUaE1mGOdBv2GsDKPL8ra5rmsYxDKQ3XcK2LCRPNDQxDIOhhgAGTNNcXAxxLLMXx3b2Tzme+7AUAvDbl21J5GtN4vj0DN4dPpt1FXk7Ek2ncyzDfI989YtBAIalRFEkeyTR6sDCvw6OgMCLxYk0ZnihetWAtJA4cSwTr9Qb1+1bXSsV8r5my7JGZBPoVAKrFQt3hrnzY991vd+xKEk0h4N+ZCOk3d69ff4UhLSJepeo8IJ0lvzrT1YQwo5+wm1tbev/CguLG4auz80+ymZzlXL51YuXoDw4NY0k8fT8Ipsvkb3eSmjTsmzfMI6mQORkLCqSaNnOoD8AkKVudE2FI4z8lCJ7EFuNOlxs22EGfhqA1effv2M57jAez2QzDMM+fPIGiJXl1WarS9Txy3MMtMwEH8tIWV5YSiWSOI4/mJ76Z5jjtIkWTB7znCQK8A31ShmIXqcNY1UFiTwLpuLFPLjD0vRYEBBCjVrVNwyIk5MkxPR5Nn9ZkBE6PjhUVbV81ZFkjaFHKSwJvT5/+ihL4/RFOV+61lQlvLkFJffYbDbkSMBwqwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 02",
              "title": "Featured 02",
              "src": "/static/a393925eb07480025abc2682f1ad7beb/70165/Featured_02.png",
              "srcSet": ["/static/a393925eb07480025abc2682f1ad7beb/d6747/Featured_02.png 288w", "/static/a393925eb07480025abc2682f1ad7beb/70165/Featured_02.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="Path to Design System Maturity" href="https://medium.com/ux-power-tools/the-path-to-design-system-maturity-d403daba692a" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACG0lEQVQoz63SXUhTcRzG8f/O/5x1nM2XzFxWEhkUhgZlGgqiEeGVRbWwQkoyECRZhSVdWFBQGQYWaIuioswYGLGs1GAznW6ap4S8iQyiRmXaRRf2wtRvhyXRXTdefG6eH89z9ROa1BBCzJ15CToWmwUlVkWxxc6KMy00JaHoKShaGhZrGrqWSpy2CLuWQqJqZ4GMiUpSVJOMEnqeWdxgR+Yko6zP+2PdJmRWKXKtE3XVQaxL6rAsqyPdUUNR0iHyk11s04vYo2RQKjNxynicqm6yIeIq1qCXrzRlYSnbFyX2ViN2NiGclxBbPcjNw4gtL8jP7eVoRheVmT7qHdVctRfTHF/ClYQVuBMduBNSEcsvF7O0sYjFjSXIhnqUi+dRLlxHPT2CPDOM7eR7HDUzzK+dYn9FhNbtv3DvmiZYeIPRnMO83lhLOLuYT9kFfMwuROR1HiC3o4ysjirEYy/iiRetPcDqtu+k35+kwPODqtsRylsiNDVH6KmforNhhvAJHz9dN5k8coeIq44p1zHTccS5iRbOfr3FqYk2do9/Zsf4GJVj37j2AdxhaH0H/jfQNQqvRmDMgPBLmA5+gV7zEHgL/WYYHDQ9R3gGvNwLPTC10xoKcjcUwhMc4GFgCG/fEI8CBk97DDp7DbqfGfT7DfpMg/4ghi8QNeTrNvmjRIweg1QUVClNyl/yvyTKLPkPIVU5t49ttVrndPA3TH2ZC9b4dTIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 03",
              "title": "Featured 03",
              "src": "/static/1b155b78187f8ee32488c8d5524dcb1e/fb070/Featured_03.png",
              "srcSet": ["/static/1b155b78187f8ee32488c8d5524dcb1e/d6747/Featured_03.png 288w", "/static/1b155b78187f8ee32488c8d5524dcb1e/09548/Featured_03.png 576w", "/static/1b155b78187f8ee32488c8d5524dcb1e/fb070/Featured_03.png 1152w", "/static/1b155b78187f8ee32488c8d5524dcb1e/dee76/Featured_03.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="Responsive Web Design Podcast" href="https://responsivewebdesign.com/podcast/ibm-carbon/" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAA+klEQVQoz61SuYqFQBD0PhADrz/wa0QDj0gNFBMVwUDQUCP1r2uZARd30H3JCwpqenqqq5rhJEmCLMsUhN/Pn/DUy92FrgaWswPZ2qMg26BpGniep1wURQrCFUWBqqr07qrd3/8RvKZnWYau6xBFEfI8RxAESNMUTdOgLEssy4I4jp8jP7mbpgnbtmEYBrRti3VdcZ4n5nlG3/d0ALm3bfs3xatDEokIHceBMAyRJAnGcURd19RVURTY9x1VVdHojztkHbquC8Mw4HkeLMuiME2TwnEc+L4PXdffd8i6FASBNpM4hF9nAsI5jnsUe3X4CWQt//7Db+Lrgj87WQr4OPCXlwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 04",
              "title": "Featured 04",
              "src": "/static/e78e6a09653d644ead610440b64a544c/fb070/Featured_04.png",
              "srcSet": ["/static/e78e6a09653d644ead610440b64a544c/d6747/Featured_04.png 288w", "/static/e78e6a09653d644ead610440b64a544c/09548/Featured_04.png 576w", "/static/e78e6a09653d644ead610440b64a544c/fb070/Featured_04.png 1152w", "/static/e78e6a09653d644ead610440b64a544c/dee76/Featured_04.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="UX Pin Virtual Design Summit" href="https://www.youtube.com/watch?v=eSvq5MieOdw&t=144s" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAAxUlEQVQoz61SOwqEQBQbxWIsVbT0Amqjjd/SQsEzCB7Eq0fyYBd1d2VWLMJA3ntJIKOUUvgFrTVc1z2A3NWNOhOWZcmbZRn6vkdd1+i6TlBVlXBpmh52LwVt25aXAp7nfRz4vi+z/a5RwrIsEQQB4jhGkiTI81x4mjCpccK9YBRFmOcZy7JgXVdM04QwDO8J8oiCTdMIaFAUhXC3BFkGWz3PydHg71J4NI6jpGnb9t3yMAxiZlzKC47jSKPfwJnxP3wAzwpufIv6LWwxSZsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 05",
              "title": "Featured 05",
              "src": "/static/958c87f4b1c00d6b9cc94c229111ede9/fb070/Featured_05.png",
              "srcSet": ["/static/958c87f4b1c00d6b9cc94c229111ede9/d6747/Featured_05.png 288w", "/static/958c87f4b1c00d6b9cc94c229111ede9/09548/Featured_05.png 576w", "/static/958c87f4b1c00d6b9cc94c229111ede9/fb070/Featured_05.png 1152w", "/static/958c87f4b1c00d6b9cc94c229111ede9/dee76/Featured_05.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
    </Row>
    <h3 {...{
      "id": "articles"
    }}>{`Articles`}</h3>
    <Row mdxType="Row">
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="What’s around the Carbon corner: October 🎃" author="Josefina Mancilla" date="September 6, 2019" href="https://medium.com/carbondesign/whats-around-the-carbon-corner-october-a47532f2933" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABIUlEQVQoz5VSu07DQBC88A4BCpoAFaKDBDuOkHABH0ESB/MQCCEMIggI4tGRmgLlCxAFHzrMXAyyEJbOxehWs3Nzu7dr9k0NWXTNhj0jnoelGi6nPcsJisVFqeZHm4X5SwhtCm/KDfTnArwvhbivBL+xTuXa/5jlGrbMOh548XzSQzLl4aA04i8Yi1NOGmdDvX5XaeB0vI6jsTpicjI6Znw2sWlzhSrsUHw94+Otuo2P1R18Eq+LW/ha28XLQhO9sm81hQx7/KcBTYbLIYYrIZ5p9DTf5GB83M4WrHDUcmAnqkm2CLW+x39T28o5G8bpUB5ZjaarQVyxfa1MQohTTprYtULt2QkHovaS1MiC5uKUi1wrzC52JweFF7ub87qL5huqa1P5DQFe0QAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "october release",
              "title": "october release",
              "src": "/static/409a771650bb2070570e20be8d675217/fb070/october_release.png",
              "srcSet": ["/static/409a771650bb2070570e20be8d675217/d6747/october_release.png 288w", "/static/409a771650bb2070570e20be8d675217/09548/october_release.png 576w", "/static/409a771650bb2070570e20be8d675217/fb070/october_release.png 1152w", "/static/409a771650bb2070570e20be8d675217/dee76/october_release.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Carbon for IBM Security" author="Jen Downs" date="August 20, 2019" href="https://medium.com/carbondesign/carbon-for-ibm-security-986ccfe5c012" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25990491283677%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACMUlEQVQoz03OzU/TcBzH8fb3/OvTr8/t1rVb162DDZiOQSMYERxQRU4cFBOxiw+JePGgB40s7ODFg4nRP9jOk8nr+v58v9JR8uW4szjpLB+ly4NsuZ/dFtntpL/Y6i9G+c1g8C0b3HTWFvFw2dz8Hox/uJOf5vSXvvubFX+ku/7z7eDlNKwmjfm4Od9szofRfNCqeq0qjaskqaKkCtvzIH3r9a6d/KO5/tkYfVU2b+jWQuqZh7l9nNunfafMnDJ1y7Zbxm4Z+aVrzWx75galEzy2Gudm60K0L/W0UnvveP4eDz5IkTZJjJ3EKFpGEYmiIYpAFL5ZWPp2PztJuzNdTA1rV3f2NO9AC4+V6CmPL2j7GU5fSAFfC5VRqG4E6oanjpyaPtJZPtk6PT979eb1p8HaQ8wyVYy4OebOLvPu0/CINE9R60yyaMdhWc3mmcUzwbtC6XLc2r/3xLZ6V1fXk+1DCbhMbVM1o8Y6scbE2cHeHvIfSII0BYkFXdFprNFIZRFFfhwNs+748nIeJ+uSbBDmYRZiJcFaD4shsu5AZypp2NdJWNNIqJKAE59jlyKbEcsym4GfMGYCoECkQ2wi6iEeIS2FRg7NocSRpWKnxrHDsE2RRZDASEdQkWUqywgAAgAFgAFYTxiQOJA3oJoArStRaDAkahQJggyMtDpDkEFAISC1uv8Hr1YgA0gDxAbMB7wpYaAQqNUwVPEq43B1h4BVAGUZ/AfKAK++QCrAAlDnL7TsSzaIPWDMAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "security pic",
              "title": "security pic",
              "src": "/static/2a156a290896898147238a99263d06a6/fb070/security_pic.png",
              "srcSet": ["/static/2a156a290896898147238a99263d06a6/d6747/security_pic.png 288w", "/static/2a156a290896898147238a99263d06a6/09548/security_pic.png 576w", "/static/2a156a290896898147238a99263d06a6/fb070/security_pic.png 1152w", "/static/2a156a290896898147238a99263d06a6/9a4ba/security_pic.png 1262w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Carbon: July recap + August preview" author="Josefina Mancilla" date="July 26, 2019" href="https://medium.com/carbondesign/carbon-july-preview-whats-coming-in-august-7a2483ea6ac9" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABAElEQVQoz51SXUsCURT03/vUP5AiEOmliB4CJY1q/QoWtdL1O0xdF/oNd6Y7e1EWuYTrw3AuM3OGe+65hXhEHmM7BHcRufkA520ceJ3FSZPH11vwhk3IyQsY1cF22dZG5myrNHl8od7AxJpHT+CyCy50q0+HRcdy70i1JG/g+Bn86dsGjfeFFGs77ipEquUK3I3JWQD27sHHomGnAgaXhtULw/4DOH1znlyBagpvwdeSYXBl2L0Bm9eG4d0ZgRpHi9C7/X4zHVucFrEenDFyMiWHNbfdecth1nQLEidNnpMDdaNVzwVpswraQ5y0ODoxMBuqd/Ihzvux9/U/ZL1Z/AGiTSpzBlTieQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Aug preview rev 2",
              "title": "Aug preview rev 2",
              "src": "/static/2da540366f2ae903eb55acb32be3f234/fb070/Aug_preview_rev_2.png",
              "srcSet": ["/static/2da540366f2ae903eb55acb32be3f234/d6747/Aug_preview_rev_2.png 288w", "/static/2da540366f2ae903eb55acb32be3f234/09548/Aug_preview_rev_2.png 576w", "/static/2da540366f2ae903eb55acb32be3f234/fb070/Aug_preview_rev_2.png 1152w", "/static/2da540366f2ae903eb55acb32be3f234/dee76/Aug_preview_rev_2.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Carbon June(ish) preview" author="Alison Joseph" date="June 12, 2019" href="https://medium.com/carbondesign/carbon-june-ish-preview-9c5843258933" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABCklEQVQoz52Sy07CYBCFeVEfwfAILkzcydpEl6KI8YoYWRgB0dq/gNU0BkUpmvgcn//J4CVSTWFxMn9nzpzOrRCO4DduXiBKoTOE+t23X2/5FBMnK7eQJdYbw34PKg5W6rDjbSW0t3yKiZMlOiUYeFL/Fba9QCOB03u4fjac+ArPEouJE+QV1N93Izh/sBYldvkEXe9vDqAaGSe3oBL3unB0C8UtWDqExTIsrMNaEw76npPOKKg5lQMoNWC5BqsTu9GeCI7naLn1CPGbfWtmyTtcDedoWcmbvrqqs1OpxXAc21K0ccVyL0Wn4Ly9GJiAtvwT8inmRjnP5ks0tTllwc162J/2P4R/VPgBRqEWJ5oiFgYAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "june option 3",
              "title": "june option 3",
              "src": "/static/54a53bcd8760b98215387b6438fdf848/fb070/june_option_3.png",
              "srcSet": ["/static/54a53bcd8760b98215387b6438fdf848/d6747/june_option_3.png 288w", "/static/54a53bcd8760b98215387b6438fdf848/09548/june_option_3.png 576w", "/static/54a53bcd8760b98215387b6438fdf848/fb070/june_option_3.png 1152w", "/static/54a53bcd8760b98215387b6438fdf848/dee76/june_option_3.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Carbon v10.3.0 (May 2019)" author="Josh Black" date="June 6, 2019" href="https://medium.com/carbondesign/carbon-v10-3-0-may-2019-41b1f970f0ce" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAABBElEQVQoz6WRzU7DMBCE+/4PxYUjHIqEqiB+CrRJmthOvLbXsZ0L46SpKpUiENLI2tj7ZcfjlXHhD7KTls/VZYfleCpO9Sw3BI7Y/w6eW1XvyA4oZGc74rPTsP+I2yJqCoYXmGzoyc+rUHZXKTCiswdBdau18RlmnIaXIt3dpKd18ilTE+yGWmjZu0YQhjeSAB8kYQe87Bz7aOHZZ+D+dnx/jhwWGADmfFaqn0yWTd92ppGmlabKk/mgUi0T3O7K9LgeNw+jjwsMwRu684U1Y1qrjDYDLOCnGFiKtG8T7vz6NhabUchjZkfYXoRsz9J2nIWCkXa4kvY10aQ5Wvr5nX+vf8Ff27hxgCHQDFoAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "10 3 image",
              "title": "10 3 image",
              "src": "/static/bf06d74c14c38c42cfc9243126462ec2/fb070/10_3-image.png",
              "srcSet": ["/static/bf06d74c14c38c42cfc9243126462ec2/d6747/10_3-image.png 288w", "/static/bf06d74c14c38c42cfc9243126462ec2/09548/10_3-image.png 576w", "/static/bf06d74c14c38c42cfc9243126462ec2/fb070/10_3-image.png 1152w", "/static/bf06d74c14c38c42cfc9243126462ec2/dee76/10_3-image.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Essentials: Learn to build with Carbon" author="Matt Rosno" date="May 15, 2019" href="https://medium.com/carbondesign/essentials-learn-to-build-with-carbon-1d11ce05125f" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB5ElEQVQoz4WS227TQBCG/R7cENu767XXp/jsYJq0ObTEQYFKQYFWlUB9AC645tF/ZrYUrAqJi18ez+x8O/PbTqAUAt+HfUoFRXGoI5gwg1LSimPOKToj/Bnm/Q7F4sbWwiix0qGxdUdpDbVYQEUhgjiB2xa4ak4Yij10EFpxzDnJAKUtjKFJ+Qbt5ouN2/UZwnsNh6eT7w8Qn8+Q9/dwtytcFkdoHWK/+GbF0FX9ETf9A06rH1DChzd7hXIYUa9uEectuusHSMo7KggglxdQaYpgXsAdGqybT+jnO+RxY7WgmHNKCcQhneOtqDlrrmjCM/J2jW579zShmniohID0XJgoRWYq+NK1Sk2JSJMdwsOMxD28Pm8RJXNEcW59ZE+dgCYUUkIQLDKGCgae72JGtxUmxSpvMKQFcsp31LwreoSBxnrewRBQSgElnz4esxwGtV2Pqq6RZpnVeDhiOx7wobpAHWeoSMd6ie+Xt/i5u7O5U7eGRzBNcAax7FeWRM+yHMbEaNrOAjebHZabLa7zDkNWoU8K7MsBj29HfB3eYawGmrq0wGfQM9SuzFDf91DXDZIksbFPpvNKvCYr/v0fsnc9+0a1KegPcJrk9Rk+XcH6az36e86fvL+EOtPml7f9T//q+QVVW1XENoBPPgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "code palette",
              "title": "code palette",
              "src": "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/fb070/code_palette.png",
              "srcSet": ["/static/1a67f5c2bd6548d9e8bb0368814a7d1b/d6747/code_palette.png 288w", "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/09548/code_palette.png 576w", "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/fb070/code_palette.png 1152w", "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/dee76/code_palette.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Carbon is moving to a monorepo" author="Josh Black" date="May 10, 2019" href="https://medium.com/carbondesign/carbon-is-moving-to-a-monorepo-c6bfcbe87de0" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAA90lEQVQoz5WSOQvCQBCF/f8/QGxVsFHQQvFsLETBRsQ7iTFeiUfwxESjPGeXRGTRaIrHDLO7H/NmJyQ5DkSNbrdXlCn2bJtLFs4+vQ19KorQ7HrF9Qv2Ezi+31E/HpE2dK4G5awWGOhZ7ZDNtK4jPtUQJaWWC3QF638DvZxBEvMZB+Y362AzZJc88Q4tCxmymjEMJAk8uF6hCPMVwb6WFZpXdb9DWJEQow77BFSpJgftUHLn17pc+MwqponIWEHzfEKbuu7Y1n8dvtutHQ4obDcom1sOzNHalCgvUo2dyUEts/WYPB5QXWluZDW/1flqmcXhF/l9yhO6+DzduFcIdAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "mono repo teal",
              "title": "mono repo teal",
              "src": "/static/5912252ae7c2b12f24eaf492fe418d49/fb070/mono_repo_teal.png",
              "srcSet": ["/static/5912252ae7c2b12f24eaf492fe418d49/d6747/mono_repo_teal.png 288w", "/static/5912252ae7c2b12f24eaf492fe418d49/09548/mono_repo_teal.png 576w", "/static/5912252ae7c2b12f24eaf492fe418d49/fb070/mono_repo_teal.png 1152w", "/static/5912252ae7c2b12f24eaf492fe418d49/dee76/mono_repo_teal.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Carbon dark themes are here" author="Lauren Rice" date="April 29, 2019" href="https://medium.com/carbondesign/carbon-dark-themes-are-here-a18910a0f910" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACMElEQVQoz6WSTW8SURiF+QWEDxkmKlU7wDABi0CroBWMQLS1VRfiQo0xYaV/y42JLoyJaWqiiYvGLmxTP0jaUhOwUAbGFmaGGbAEjnduh0LXvsnJndk8ufc9jwVkBoMB+v0BPY2oqgpFUdCSZWiaBlEUEY1G4fV6IQgCDc/zYFkWDMPQ0+1202+LARifIdBIt6uhd9hFrVZDLBajwMlJDhzHHQNdBGKz2eFwOCjUYlKwuSWiXGnRXwPWJrdbW/+F7R0RktRAhNyQ93mxMHcLqVSKwg0gy7gwm4gjEonA6XQeATdKTbxeqWDp0zZKu02g38VKoYqXH4t4t1zA2kYR09EwEuk53H/2Avl8HslkEjarFVPx68g8eIpcLkehlkbnEB8qKn4QzpuvEpqSjN2WgrfFOlYrGl6t1lD4vol4OgNh/iG4q/PIPHqOa5djYC+QFaTvwhWcxczCY4RDAiwlrYPP0gH29R6Wy2365J+NfSz9rqIsNfF+pwlZrCKWvY3gnUUEozMILz6B13ManlCYALM4Q8DcjXs4ZbMePflbS8WW3IHe69N9tskOv+zVsb73BweqhrrZsnAzi1AqDeHiFHi/HywpYeJKAp5L02DPToBxucxSxqZvtvy33UaHnB1dJy2b2pw/h4DPhwDvH7Vst4MhZRgw2jJ10MxgTBuZeCjLCvFQP/bQZ8CIg4FAYOQhgbjNUA+H7g19HBfbiK7rJ8Q2YCeABDKEjTw0Qf8DHOYfP/pDvsfuJB0AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "carbon themes",
              "title": "carbon themes",
              "src": "/static/66fdcfa7ca2906fc119a1c7d32e38981/fb070/carbon_themes.png",
              "srcSet": ["/static/66fdcfa7ca2906fc119a1c7d32e38981/d6747/carbon_themes.png 288w", "/static/66fdcfa7ca2906fc119a1c7d32e38981/09548/carbon_themes.png 576w", "/static/66fdcfa7ca2906fc119a1c7d32e38981/fb070/carbon_themes.png 1152w", "/static/66fdcfa7ca2906fc119a1c7d32e38981/dee76/carbon_themes.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Carbon v10 Release" author="Robin Cannon" date="April 4, 2019" href="https://medium.com/carbondesign/carbon-v10-release-812eacb7da7f" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACHElEQVQozz2TT09TURDFL6iFlhL5o5SCAi3VYoJs3RgTFwJCpRgQLaioiJgoJoaVrl2ZmLhw48LvRV9L29fXkn6L4/wu0MWk7bkzZ+acmbprz+vKH5xq4lWom7tNxdcquvWhrdn3LY3vNDRpeGYv0sy7yH9P74TK2hs58ScVXwPGb7jc9Rd1D/QVAiXXqz4BDLLe5bJGt+x9u+mDgkuG8UYOuYNPq7qyEhgWeszBPLpVU8/S8XnyWbeBYlW9S2XFi4HuHDU097Whwc1APYvlroqJl6EuPy772qHNmscck9FpxEjT513AkJxYs+6rZT341bQIldwIFFupKLffUr/JZXpsGDayqTeRr3O3D9oipg248bqp4Wc1LXzpdL2Z/1bXvR+h7v9sauF7XWOlujLmJzlDGyeeMLvX6oaDALLx7YYnHCs1vAVTu5Fyn0IV/kV6+LupR38iLf+NTHpo+S3vJxPSOGW1028jL9tBBICxyEibLwQFMZOAh9nDE+WPakqsB14WQ+AfkTA/8T5lg7AsR4eL7fUXKpq0KZFx1eS4xWMNmI+pUugjWTzDmIQcom818NfADuBySMRDPpFMcm6/7QOPwHhngxRgR86+4xeT8o50clDr8h9PNWtbw2gA7uruYccn4QuNsIQFoYTtjxjR/OeOv1tqOHrq54zL4R0+ZKzjxb+A24KI3wSTchrcKBNBGDOpqKDZjDXmbqn7D6ih/1qnMkxUAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "bee 70",
              "title": "bee 70",
              "src": "/static/76fff6ed801ff9bb85168eb592bba8b7/fb070/bee_70.png",
              "srcSet": ["/static/76fff6ed801ff9bb85168eb592bba8b7/d6747/bee_70.png 288w", "/static/76fff6ed801ff9bb85168eb592bba8b7/09548/bee_70.png 576w", "/static/76fff6ed801ff9bb85168eb592bba8b7/fb070/bee_70.png 1152w", "/static/76fff6ed801ff9bb85168eb592bba8b7/dee76/bee_70.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="Design with Carbon: Update your Sketch symbols to V10" author="Anna Gonzales" date="April 2, 2019" href="https://medium.com/carbondesign/design-with-carbon-update-your-sketch-symbols-to-v10-ef3132ea2e97" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACaElEQVQoz4WS2U9TQRxG+dt88IknIkYFDRTQAiJBYoh7CAooAlHDYkjZ2rJEFsMm0ApULE2A1EJBIIKxpMgipHShd2nLce5FCYkmPpx8D3fmzDe/uUkt1l5Ky1+Qk1fI3cdPKK96xeWMG6Rn5XEhzUCqIM2QS3Z+MQXF98gvKqHwzgOd6zdvYzAWCG6dktTa0UdRyUOSUy7xqKwC54yLdksH1s5ukVbazBa63/bQ0m4RYiMXr2ZxJdOoH5qabhCHZggyT0nSGtbWmSitqMHUMcD67jEbuwl8B/AjBP7gSS77wrxp7aTBZKFe0NhspbbeRHl1HZW19VTWnJDUbOmhd8jOxKSD171eUurjGFpiXDOpJL+UOV8tc65KwdCscBAIo8iSQCERj7Gytk7foI2hsSkGRyd1dGH/sJ0Rm4Mpl5clXxTPRlDkESt+meVNiVW/hHP1iKIuFaNZJVeQ2ZKgcXSHJa+X2c8rzHu+6JwKh8YczLm9RKMSgUCQYCiMLJqoaozjuMr2fpTnIwplAwpPB1Xu96mMeGQksf4wrBKMqARC8tmGH5l2ufH59/i2uSPYZnNrj43vW6yub7K1/ZNoOEhcOYK4rGdMjugQl3TiSuS3cMjG6IdPulTjvX1az/HJGdq7+qlrMuNwzrO7HxItxA1Cin6TyWVJb/xsRKVyWEP5t/APmtDc9U5/VduUi5lZD665BZyzi7jdbppG/WS3JchuVclqjZEj8i+h1u5sQ03YYLIyNuEUrzilv+jwuEPssbOwtKbPWZujJMlEIkf/F7afEf75pq3VfpHF5a9EpRiHYgzBsCAi8wt0+LfXDE5tAgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "designing with carbon",
              "title": "designing with carbon",
              "src": "/static/b1c4ac6137f64560f93bbb74fd35e492/fb070/designing_with_carbon.png",
              "srcSet": ["/static/b1c4ac6137f64560f93bbb74fd35e492/d6747/designing_with_carbon.png 288w", "/static/b1c4ac6137f64560f93bbb74fd35e492/09548/designing_with_carbon.png 576w", "/static/b1c4ac6137f64560f93bbb74fd35e492/fb070/designing_with_carbon.png 1152w", "/static/b1c4ac6137f64560f93bbb74fd35e492/dee76/designing_with_carbon.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="Minimal CSS with Carbon" author="Josh Black" date="April 1, 2019" href="https://medium.com/carbondesign/minimal-css-with-carbon-b0c089ccfa71" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABLklEQVQoz62Sa0/CMBSG+U8qxgu3jTGY3a3r7hdBJRkS4u/wkz/59bTIsgkJCfrhSU+75um70/b6D2PcDjTc0Hh1N1TjJUhH/3GCnhTKYjhdwLR9aKZNOIqJyc6izW0M9LlySGlPFtf3I1heiNd6Bz/OwUIBOwqpLrokBbyk7KwFaYUZ85uUJNxH1hcOnDBBFNfYll/Y5J9g6RKMDnBFCjfM4AcJOI9VfUBKjSfvWDi1XHA6nUcV0mSLsvyAqN4gyhdExYrSldDXNcbvOzgk8VrC2SmhTMjpI6PfYnEKnuYQ2XODJ5Os1iTdwKb6fEJaFNQPJ1/CJYEf5fBaqH6RIBDZUQ9Nm7eEnVvmKr6CGv0bow3tMX72jQyrdcutN3TpGzzImncoJ3/lZML/EH4Dwd2TAESZxrMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "minimal css",
              "title": "minimal css",
              "src": "/static/fd835439f8b3a8e647903c7c173e06ea/fb070/minimal_css.png",
              "srcSet": ["/static/fd835439f8b3a8e647903c7c173e06ea/d6747/minimal_css.png 288w", "/static/fd835439f8b3a8e647903c7c173e06ea/09548/minimal_css.png 576w", "/static/fd835439f8b3a8e647903c7c173e06ea/fb070/minimal_css.png 1152w", "/static/fd835439f8b3a8e647903c7c173e06ea/dee76/minimal_css.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="What to expect in Carbon 10" author="Robin Cannon" date="December 10, 2018" href="https://medium.com/carbondesign/what-to-expect-in-carbon-10-5af1bd6e25f6" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAADLElEQVQozx2S60/TZxiGf/+AH8YOYIXWUi0kmlmCCMwpByOVkeLkIDaloqWgFRbKjBWZGQkrjmGpnKIT2wq11VkEbGgBzZY5dOu2DiyKZyglm2ZmiRsmyz5e+8mTvHmS931yP9d95xVy8/dQq9fSZ2unv6eTUo0GjbqAi+fP8tvPd8S7Mxw1Gui2fcXdcAj/yBDh0B167J1MBPwsLT7mxfNFFufniM4/QNiaWUhpkRpHrx3PgIsv222cOt2FqfYQur3lWBrNWE82U1FSwkz4F366/QPO/nPs11aIM0Yezs3w18sllv/+k6ePZxF2F2pobW7C47rAiO8bghM3CE5+i9NxgbPdXdjb27k+PMS1K14ezd1j3D/KoKOfm+NBfg39SGzhEf/9+0o8/4ikMYT8D7dRpdWiKy7G0dfLzeAYoanvuT91iycz09xwuUTrIZaiC0SfPaGn08aJo434vB7u351h+dVLXvwRE+0+5fXrZYSvu7tRpaSSm6bivEjU19XFqO8q1y87CQx7GfI4mfpuggf3ZolFo9htNi6K9BFRLCIufFP1YjyZqjRii1EEa5OFnKytlBbs4qp7gKbjLZhqGmiob6S2+jA1hkO4XU5mRYHYswX8Xi/Tt28RFrN8U7ORCJoPMkhfp6CluRnho7w8Nm1UkbU5m4qyMiq11Xy8qwyj3sT+CiPaUr1Ibef350vMh6fxfXEY/6ibtgYT0YcROk53siNzC+YjdZjqPkWQSqQo5etJVaSiXKtko3IDRp0RU3U9NXoDBl01PR2tTI4MEPT7OdV3Eqv1M06YzbS1Wjl4sIaS8kr2VRqpMtQhyBPlJCclk5Kcgjwxmfi4BPR792Fpa+HYsePif7NwzdPL5yLRgZI9nBtwMOi+hEKWwub0bAoKikhdv4HV70qQrZEjyCSyFSGlXElSgpS4VW9jrDqA2+3gineQiTEfYyOXOWPvwGKuYzIwzHggQFVZOTt3qFG9n4EkPpH34uJZk5AkWl4tXSFUyNat0MWLD9kZ2eTk5rFt+3Zy8vLZqVZTqClCs1uDTq8XezGfmI6g2rSF9LQs1koVvLUqjoR3JPwPI0og6RUji2IAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Carbon what to expect",
              "title": "Carbon what to expect",
              "src": "/static/3a8a8619b5bf2ce1b6d50219e5729509/fb070/Carbon_what_to_expect.png",
              "srcSet": ["/static/3a8a8619b5bf2ce1b6d50219e5729509/d6747/Carbon_what_to_expect.png 288w", "/static/3a8a8619b5bf2ce1b6d50219e5729509/09548/Carbon_what_to_expect.png 576w", "/static/3a8a8619b5bf2ce1b6d50219e5729509/fb070/Carbon_what_to_expect.png 1152w", "/static/3a8a8619b5bf2ce1b6d50219e5729509/dee76/Carbon_what_to_expect.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="Up & Running with Carbon React in less than 5 minutes" author="Josh Black" date="October 19, 2018" href="https://medium.com/carbondesign/up-running-with-carbon-react-in-less-than-5-minutes-25d43cca059e" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAA3klEQVQoz61SwQpFQBQdwsbCRrFQdpZWNiQlJUX8hBQL2dgoxcJOfvm8Zkq9V+9Njd7idG/d25lzzlxCCMGf8XuoaRqyLINt23AcB2maQlEUcUJJklh1XRdBEGCaJgzDgDAMYVnWx46QwrqusW0b+r5nhPu+oygKMYWyLLOa5zmiKELXdVjXFcdxoG1bxHHMYnjf5RLeVqi1pmlwXRfmecayLDjPE1VVwTRNsQzvl33fZyrHcWS2kySB53k8dfwMDcNAWZbsMygx7XVdf342FKqqfu0fE965cs5EnFAEL1MrApTgpPqpAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Article 02",
              "title": "Article 02",
              "src": "/static/fcf5a9c51f03531d1560b086fe86bbe4/fb070/Article_02.png",
              "srcSet": ["/static/fcf5a9c51f03531d1560b086fe86bbe4/d6747/Article_02.png 288w", "/static/fcf5a9c51f03531d1560b086fe86bbe4/09548/Article_02.png 576w", "/static/fcf5a9c51f03531d1560b086fe86bbe4/fb070/Article_02.png 1152w", "/static/fcf5a9c51f03531d1560b086fe86bbe4/dee76/Article_02.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="Hacktoberfest with Carbon" author="Josh Black" date="October 16, 2018" href="https://medium.com/carbondesign/hacktoberfest-with-carbon-95c48943f586" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.199999999999996%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACqklEQVQozwGfAmD9AKerrqOiqZ2aobCqq7auqamlo5CWlpSwtHOWgG2IeUNpaE1sbHSTpHySnmWChCd3lT+GmUCUqHBwiKt9kwCrrbCmpqujo6einptvbm6NioqMlZFagHVghG1zi3ZTgn5bgIB4lKNegJUvN00pNVGDY1VfkZddYn1VUGYAp6itoqWnp6eoW1dYJCIpLi4xlJKIg4NogoZtiol8SHp6UXd5dpSkW3mNND1QS0tWgXptNYOVd2R8RkleAKemqaWkp6KlpoNya5VfQEIlF01XY7OlmLeljqueh2uAcV50cGyRpVFuhDI7TIdnV1R7gyqFoqB2jD5DXAClpqufoKGmpaSgm5laSEYvJSkZRnM/VmyRcDiliErNt4HLuouppoVNX2ssOE0sO1QxPVMySWJERFo/QkoAopuLoaKmp6Gbd3+HEjddGTFMJk51GjFMVT0gjGctqpJd1b6DqplqOENLICUyLzZGLDdNT05MmINPsZJPAJx3Nn92Z257jUxcciAsOSlFWyZUgB8vQCMdG3FTLLCSV6ODTEthaR4oMRQRERESFiYmJq6UV8ilWbydVgCsi1OYhFNubmxRODMfMUJBZogqUHYNGSkuKySPajiGVyJcQiYmMTwSEhYICQ0SERGNe1TBo2KsjE6lh0sAon4+pH9CkGEkd04nlmZVjH18Gy5GFhwhfV0vhE0WcUMSg3ZYkYBkdF9CVkg2aWdgy7eIpoE8lG4ujmgtAK2IPauFOqmEO7CENXRTMylJYUE/Mn5jNoNcKXFLHZqNecjKwNXQqry5sbSjlYuMj2htdpV+WqWAQKV7MwCwi0KuiD6ogTiedjFbSSglKih6YTJ6ZDp0ZUVnVDB5Z0uxrqjN3ufK1+LAztmywMyVn62ywNSwpZN7WSRAhB1lLWaPzAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Article 03",
              "title": "Article 03",
              "src": "/static/d1f1a8da08618409d5cab8cdcadb169f/70165/Article_03.png",
              "srcSet": ["/static/d1f1a8da08618409d5cab8cdcadb169f/d6747/Article_03.png 288w", "/static/d1f1a8da08618409d5cab8cdcadb169f/70165/Article_03.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterMdLeft mdxType="Column">
  <ArticleCard title="Data Table updates in Carbon React v5.22.0" author="Josh Black" date="February 20, 2018" href="https://medium.com/carbondesign/data-table-updates-in-carbon-react-v5-22-0-6da0c24a96d6" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABe0lEQVQoz5WS206DQBCG+2i+iw+iD+CFD+CViddWE9uYSGMPqD1QIFKo2NbDnT0CLdDCwu/uIrGlTZOSfJkhk/3335nJdd4+oXU/wKLa6aHy3EZZlHbySGm2JCiyhLYkoVZX8GoMsK6RS3+M92+IDRVCpQ7DpMVun6MxjASd5hfFPk6u+jjPD1B4eIKkdqGbX7sF2Y0tRQeJgFUQUgiCMEJIYk4cxzi9jXB0RnB8GUGoyVTQ2C/YaGuwHQ8/YwujiY35woPnBwnLAHPXhWNbWPo+qrQ9TdmgL9oj2JQ7WLg+FbMwnTmIoog7i3mMYLker7n+CtWXHYIsyQoyV8PxDJOpjTAMQQhJCAmcoY3haAbXW245ZGw5ZD3x6dOmlgPLWSD72bw2R0D7K3IDexyKdTrlcp1PVNFMukYmzXt/U06iqvd4jeX3JZEO8X8o3GGqzPaJ7Vm+UEL+TqBR4PE6w3rtpljiLlONDUEW0yU9BHZmQ3B9y7P5IaTnfgGAEgyez2zJsgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Article 04",
              "title": "Article 04",
              "src": "/static/94ecf307384dd9fad5a6c59768c99ab8/fb070/Article_04.png",
              "srcSet": ["/static/94ecf307384dd9fad5a6c59768c99ab8/d6747/Article_04.png 288w", "/static/94ecf307384dd9fad5a6c59768c99ab8/09548/Article_04.png 576w", "/static/94ecf307384dd9fad5a6c59768c99ab8/fb070/Article_04.png 1152w", "/static/94ecf307384dd9fad5a6c59768c99ab8/dee76/Article_04.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      